import * as React from "react";
import { useLocation } from "react-router-dom";
import { OverlayProvider } from "@react-aria/overlays";

import { useCell, useResource, useForm, useLocationState } from "hooks";
import { Text, Field, Button, View } from "components/elementsThemed";
import { Tabs } from "components/fragments";
import { DeliverySearch } from "components/templates/AddressSearch/DeliverySearch";
import { config, constants, Copy, Routes } from "utils";
import { initDate, setUnixTimestampDownToMinute } from "utils/helpers/time";
import { SET_SCHEDULE_DATE, VALIDATE_ORDER } from "utils/api";
import { useGlobalConfig } from "components/providers/GlobalConfig/globalConfig";
import { useOrderContext } from "components/providers";
import Modal from "../../Dialog";

import styles from "./locationModal.module.scss";

const { FieldItem } = Field;
const {
  ORDER_TYPES: { PICKUP, DELIVERY },
} = constants;

const getTabContentArrayByConfigOrder = ({ configTabOrder, tabContent }) =>
  configTabOrder.map((tab) => tabContent[tab]).filter((tab) => tab);

const selectStyle = {
  border: "none",
  background: "#f5f5f5",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  borderRadius: "5px",
};

const editButtonStyle = {
  boxShadow: "none",
  backgroundColor: "transparent",
  border: "none",
  cursor: "pointer",
  fontSize: "0.938rem",
  lineHeight: "0.938rem",
};

const LocationModal = ({ history }) => {
  const {
    location: { address, timeZone, id },
    order: { diningOption, scheduledAt },
    setScheduledAt,
    setOrderType,
    setDeliveryAddress,
    items,
    changeLocation,
  } = useOrderContext();
  const routerLocation = useLocation();
  const globalContext = useGlobalConfig();
  const [activeTab, setActiveTab] = React.useState(diningOption);
  const { isLocationModalOpen } = globalContext;
  const [toggle, setToggle] = React.useState(true);

  const {
    clearLocations,
    deliveryChange,
    locations,
    locationState,
    handleStreetChange,
  } = useLocationState(activeTab);
  const { buttons } = useCell("footerButton");
  const {
    labelTextStyles,
    buttons: { primary },
  } = useCell("locationModal");

  const [times, setTimes] = React.useState([]);
  const [error, setError] = React.useState("");
  const didMountRef = React.useRef(false);

  const [{ resource: days = [] }] = useResource({
    data: {
      orderType: diningOption,
    },
    path: Routes.FETCH_LOCATIONS_SCHEDULE_DATE,
  });
  const { values, updateField } = useForm({
    initialValues: {
      day: scheduledAt ? initDate(scheduledAt, timeZone) : "",
      time: scheduledAt || "",
    },
  });

  const formattedAddress = `${address?.street1}, ${address?.city}, ${address?.state}, ${address?.zip}`;

  const canProceed =
    locations.length !== 0 &&
    days.length !== 0 && // times, dates fetched
    times.length !== 0 &&
    values.time && //time, day selected
    values.day &&
    values.time !== "default" &&
    values.day !== "default" &&
    values.time !== "" &&
    values.day !== "" &&
    (activeTab === DELIVERY //if delivery, is location entered?
      ? locationState.lat !== "" && locationState.long !== ""
      : true);
  React.useEffect(() => {
    if (locations && !locations.length && didMountRef.current) {
      setError(
        "Sorry, we don’t deliver to your address. But pickup is available!",
      );
    } else {
      setError("");
    }
    didMountRef.current = true;
  }, [locations.length]);

  React.useEffect(() => {
    if (diningOption) {
      setActiveTab(diningOption);
    }
  }, [diningOption]);

  React.useEffect(() => {
    if (!isLocationModalOpen) {
      document.body.style.overflow = "auto";
    } else {
      document.body.style.overflow = "hidden";
    }
    setToggle(true);
  }, [isLocationModalOpen]);

  React.useEffect(() => {
    updateField("time", "");
    if (!values.day) return;
    const fetchData = async () => {
      try {
        const { data } = await SET_SCHEDULE_DATE({
          data: { orderType: activeTab },
          date: values.day,
        });
        const mappedTimes = data.map((i) => ({
          ...i,
          unixTimestamp: setUnixTimestampDownToMinute(i.unixTimestamp),
        }));
        setTimes(mappedTimes);
      } catch (err) {
        console.error(err);
      }
    };
    fetchData();
  }, [values.day]);

  const handleUpdate = async () => {
    try {
      const { city, lat, long, state, street1, street2, zip } =
        locationState.address;
      const payload =
        activeTab === DELIVERY
          ? {
              deliveryInfo: { city, lat, long, state, street1, street2, zip },
              items,
              orderType: activeTab,
              scheduledAt: values.time,
            }
          : {
              items,
              orderType: activeTab,
              scheduledAt: values.time,
            };

      // TODO :  need to confirm whether we need to validate order after adding item to cart
      const { data } = await VALIDATE_ORDER(payload, {
        headers: { locationId: id },
      });

      setOrderType(activeTab);
      setDeliveryAddress(locationState.address);
      setToggle(false);
      if (activeTab === DELIVERY || activeTab === PICKUP) {
        localStorage.setItem(
          "locationName",
          locations.find((x) => x.id === id).name,
        );
        changeLocation(locations.find((x) => x.id === id));
      }
      setScheduledAt(values.time);
      const selectedLocation = localStorage.getItem("location");
      // if pickup only, get location where id matches active id
      // otherwise just use the first loc returned from locations arr
      const loc =
        config.market_place.enabled && activeTab !== DELIVERY
          ? locations.find((location) => location.id === id)
          : JSON.parse(selectedLocation);

      history.replace(
        `${Routes.LOCATION_INFO({
          orderType: activeTab,
          resGroupId: config.market_place.enabled
            ? loc.restaurantGroups[0].id
            : loc.id,
          slug: loc.slug,
        })}`,
      );
    } catch (err) {
      console.error(err);
      if (activeTab === DELIVERY) {
        setError(
          "Sorry, we don't deliver to your address. But pickup is available!",
        );
      }
    }
  };
  /**
   * onTab Changed function handler
   * @param {String} nextTab
   * @return {Null} null
   */
  const onTabChange = (nextTab) => {
    setError("");
    setActiveTab(nextTab);
    setTimes([]);
    updateField("day", "");
    updateField("time", "");
  };

  const LocationIcon = () => {
    return (
      <div>
        <svg
          width="20"
          height="20"
          viewBox="0 0 10 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10 5C10 7.76122 5 12.75 5 12.75C5 12.75 0 7.76122 0 5C0 2.23878 2.23878 0 5 0C7.76156 0 10 2.23878 10 5Z"
            fill="#1A1A1A"
            fillOpacity="0.5"
          />
          <path d="M1 13H9" stroke="#1A1A1A" strokeOpacity="0.5" />
          <circle cx="5" cy="5" r="2" fill="#F5F5F5" />
        </svg>
      </div>
    );
  };

  const tabs = {
    [PICKUP]: config?.pickup?.enabled && {
      diningOption: PICKUP,
      form: () => (
        <div
          key={PICKUP}
          title={PICKUP}
          className={styles["locationModal-scheduleFields"]}
        >
          <div className={styles.fields}>
            {/* ADDRESS */}
            <FieldItem
              className={styles["locationModal-fieldItem"]}
              type={labelTextStyles.secondary}
              label={`${Copy.LOCATION_LIST_STATIC.PICKING_UP_TIME}:`}
            >
              <div
                className={styles["locationModal-scheduleSelect"]}
                style={selectStyle}
              >
                <div className={styles["locationModal-addressSelect"]}>
                  <LocationIcon />
                  <p>{formattedAddress}</p>
                </div>
                <button
                  onClick={() => {
                    history.push("/");
                    setError("");
                  }}
                  style={editButtonStyle}
                >
                  Edit
                </button>
              </div>
            </FieldItem>
            {/* DAYS */}
            <FieldItem
              className={styles["locationModal-fieldItem"]}
              type={labelTextStyles.secondary}
              label={`${Copy.CART_STATIC.SCHEDULED_DATE_DAY_LABEL}:`}
            >
              <select
                onChange={(e) => updateField("day", e.target.value)}
                className={styles.scheduleSelect}
                style={selectStyle}
                value={values.day}
              >
                <option value="default">
                  {Copy.CART_STATIC.SCHEDULED_DATE_DAY_PLACEHOLDER}
                </option>
                {days.map((i) => (
                  <option key={i.unixTimestamp} value={i.unixTimestamp}>
                    {i.pretty}
                  </option>
                ))}
              </select>
            </FieldItem>
            {/* TIMES */}
            <FieldItem
              className={styles["locationModal-fieldItem"]}
              type={labelTextStyles.secondary}
              label={`${Copy.CART_STATIC.SCHEDULED_DATE_TIME_LABEL}:`}
            >
              <select
                onChange={(e) => updateField("time", e.target.value)}
                className={styles.scheduleSelect}
                style={selectStyle}
                value={values.time}
              >
                <option value="default">
                  {Copy.CART_STATIC.SCHEDULED_DATE_TIME_PLACEHOLDER}
                </option>
                {times.map(({ isAvailable = true, ...i }) => (
                  <option
                    key={i.unixTimestamp}
                    value={i.unixTimestamp}
                    disabled={!isAvailable}
                  >
                    {i.formatted}
                  </option>
                ))}
              </select>
            </FieldItem>
          </div>
        </div>
      ),
      name: "Pickup",
    },
    [DELIVERY]: config?.delivery?.enabled && {
      diningOption: DELIVERY,
      form: () => (
        <div key={DELIVERY} title={DELIVERY}>
          <div className={styles["locationModal-deliveryTab"]}>
            <DeliverySearch
              onSelect={deliveryChange}
              onFocus={clearLocations}
              onError={clearLocations}
              address={locationState.address}
              handleStreetChange={handleStreetChange}
            />
            <FieldItem
              className={styles["locationModal-fieldItem"]}
              type={labelTextStyles.secondary}
              label={`${Copy.CART_STATIC.SCHEDULED_DATE_DAY_LABEL}:`}
            >
              <select
                onChange={(e) => updateField("day", e.target.value)}
                className={styles["locationModal-scheduleSelect"]}
                style={selectStyle}
                value={values.day}
              >
                <option value="default">
                  {Copy.CART_STATIC.SCHEDULED_DATE_DAY_PLACEHOLDER}
                </option>

                {days.map((i) => (
                  <option key={i.unixTimestamp} value={i.unixTimestamp}>
                    {i.pretty}
                  </option>
                ))}
              </select>
            </FieldItem>
            {/* TIMES */}
            <FieldItem
              className={styles["locationModal-fieldItem"]}
              type={labelTextStyles.secondary}
              label={`${Copy.CART_STATIC.SCHEDULED_DATE_TIME_LABEL}:`}
            >
              <select
                onChange={(e) => updateField("time", e.target.value)}
                className={styles["locationModal-scheduleSelect"]}
                style={selectStyle}
                value={values.time}
              >
                <option value="default">
                  {Copy.CART_STATIC.SCHEDULED_DATE_TIME_PLACEHOLDER}
                </option>
                {times.map(({ isAvailable = true, ...i }) => (
                  <option
                    key={i.unixTimestamp}
                    value={i.unixTimestamp}
                    disabled={!isAvailable}
                  >
                    {i.formatted}
                  </option>
                ))}
              </select>
            </FieldItem>
          </div>
        </div>
      ),
      name: "Delivery",
    },
  };

  const tabOrder = getTabContentArrayByConfigOrder({
    configTabOrder: config?.tab_order,
    tabContent: tabs,
  });

  if (isLocationModalOpen) {
    return (
      <OverlayProvider>
        <Modal
          toggle={toggle}
          onClick={() => setToggle(false)}
          modalTitle="Set time and location"
        >
          <View className={styles.locationModal}>
            <div className={styles.modal}>
              <div className={styles["locationModal-scheduleContainer"]}>
                <div className={styles.top}>
                  <div className={styles.closeButtonWrapper}>
                    <Button
                      type={primary}
                      onClick={() => {
                        setError("");
                        history.replace(routerLocation.pathname);
                      }}
                      aria-label="Close Schedule Dialog"
                    >
                      ✕
                    </Button>
                  </div>
                  <Text
                    className={styles.mobileFormHeader}
                    type={labelTextStyles.tertiary}
                  >
                    Set Time & Location
                  </Text>
                </div>

                <Tabs
                  destroyInactiveTabPane
                  type="primary"
                  activeKey={activeTab}
                  onTabChange={onTabChange}
                >
                  {tabOrder.map((option) => (
                    <div key={option.diningOption} title={option.name}>
                      {option.form()}
                    </div>
                  ))}
                </Tabs>
                <Text
                  className={styles.errorText}
                  type={labelTextStyles.tertiary}
                >
                  {error}
                </Text>
                <Button
                  disabled={!canProceed}
                  onClick={handleUpdate}
                  className={styles.updateButton}
                  type={buttons.primary}
                >
                  {canProceed
                    ? "Update"
                    : `Please enter ${
                        activeTab === DELIVERY ? "delivery" : "pickup"
                      } information`}
                </Button>
              </div>
            </div>
          </View>
        </Modal>
      </OverlayProvider>
    );
  }

  return <></>;
};

export default LocationModal;
