import { axios, config, Copy, Routes, constants } from "utils";
import { getAppsFlyerData } from "hooks/analytics";
import { stringReplacer } from "utils/helpers/string";
import { roundFloat } from "utils/helpers/math";

const {
  CART_STATIC: {
    CHECKOUT_DELIVERY_HEADER_TEXT,
    CHECKOUT_PICKUP_HEADER_TEXT,
    CHECKOUT_HEADER_TEXT,
    CHECKOUT_KIOSK_HEADER_TEXT,
  },
} = Copy;

const {
  ORDER_TYPES: { PICKUP, DELIVERY, KIOSK },
} = constants;

export const orderValidation = ({
  res,
  locationId,
  diningOption,
  mobilePayEnabled = false,
  isLoggedIn = true,
  isCash,
}) => {
  let payload = {};
  if (
    isLoggedIn &&
    !mobilePayEnabled &&
    !isCash &&
    (!res?.customerCard || !res?.customerCard.last4)
  ) {
    payload = {
      buttonText: Copy.CART_STATIC.NO_PAYMENT_INFORMATION_BUTTON_TEXT,
      message: Copy.CART_STATIC.NO_PAYMENT_INFORMATION_MESSAGE,
    };
  } else if (
    diningOption === "delivery" &&
    config.delivery.minimum > 0 &&
    res.amount < config.delivery.minimum
  ) {
    payload = {
      buttonText: Copy.CART_STATIC.MINIMUM_ORDER_AMOUNT_BUTTON_TEXT,
      message: stringReplacer(Copy.CART_STATIC.MINIMUM_ORDER_AMOUNT_MESSAGE, [
        {
          replaceTarget: "{deliveryMin}",
          replaceValue: config.delivery.minimum,
        },
      ]),
    };
  } else if (!locationId) {
    payload = {
      buttonText: Copy.CART_STATIC.SELECT_LOCATION_BUTTON_TEXT,
      message: Copy.CART_STATIC.SELECT_LOCATION_MESSAGE,
    };
  }
  return payload;
};

export const orderSubmission = ({
  locationId,
  order,
  cart,
  idempotencyKey,
  tip,
  guestInfo,
  cardNonce,
  transactionId,
  isCash,
  giftCardNumber,
  giftCardPinCode,
}) => {
  const appsFlyerData = getAppsFlyerData();
  const { polling } = config.ordering;
  let path;
  const data = {
    ...order,
    ...(guestInfo && { guestInfo }),
    ...(cardNonce && { cardNonce }),
    ...(transactionId && { transactionId }),
    cart,
    idempotencyKey,
    tip,
    giftCardNumber,
    giftCardPinCode,
  };

  // switching between routes before posting
  if (isCash && !polling) {
    path = Routes.PLACE_ORDER_CASH;
  } else if (polling && !isCash) {
    path = Routes.PLACE_ORDER_POLLING;
  } else {
    path = Routes.PLACE_ORDER;
  }

  const args = [
    path,
    data,
    {
      headers: {
        locationId,
        ...appsFlyerData,
      },
    },
  ];

  return axios.methods.post(...args);
};

export const checkOrderStatus = (idempotencyKey) =>
  axios.methods.get(Routes.CHECK_ORDER_STATUS(idempotencyKey));

export const initTip = (orderType, preDiscountPrice) => {
  const tipPercent = config[orderType].tip_default || 0;
  return (Number(tipPercent) * preDiscountPrice).toFixed(2);
};

export const orderSummaryHeader = (orderType) => {
  switch (orderType) {
    case DELIVERY:
      return CHECKOUT_DELIVERY_HEADER_TEXT;
    case PICKUP:
      return CHECKOUT_PICKUP_HEADER_TEXT;
    case KIOSK:
      return CHECKOUT_KIOSK_HEADER_TEXT;
    default:
      return CHECKOUT_HEADER_TEXT;
  }
};

/**
 * Map the initial guest checkout form data based on the configurations of the client
 * We loop through the fields specified in the config and add those values from
 * the patron context into the return object. undefined values are not included
 *
 * @param {Array<string>} guestCheckoutFields - String of fields to map
 * @param {object} patronInfo - Patron object from the patron context
 * @returns {object} a flat object that contains the fields that a client wants
 * to its guests fill out.
 */
export const mapGuestCheckoutFormValues = (guestCheckoutFields, patronInfo) => {
  const mappedFormData = guestCheckoutFields.reduce((accu, field) => {
    const value = patronInfo?.[field];
    if (value !== undefined) {
      // eslint-disable-next-line no-param-reassign
      accu[field] = value;
    }
    return accu;
  }, {});
  return mappedFormData;
};

export const getFinalAmount = ({
  giftCardBalance = 0,
  appliedStoredValue,
  delivery,
  finalTip,
  totalAmount,
}) => {
  const giftCardLeftBalance =
    giftCardBalance - appliedStoredValue - finalTip - delivery;

  /* totalAmount returned by backend does not calculate serviceFee (or deliveryFee) and Tip
       serviceFee (or deliveryFee) and Tip cannot be paid by loyalty credit but should be paid by giftCard if there is enough balance
    */
  const actualAmount = totalAmount + finalTip;

  if (giftCardBalance === 0 && appliedStoredValue > 0) return totalAmount;
  // if there is no giftCard applied, just return the totalAmount plus tip and service (or delivery)
  if (!appliedStoredValue) return actualAmount;

  // if the (tip + delivery) is >= giftCard balance, the actual total will be the actualAmount minus actualBalance
  if (giftCardLeftBalance <= 0) {
    const actualBalance = giftCardBalance - appliedStoredValue;
    // However, if the actual balance is greater than the actual amount, then don't go into the negative, balance to zero
    return Math.max(roundFloat(actualAmount - actualBalance), 0.0);
  }

  return 0;
};

export const getFinalAppliedStoreValue = ({
  giftCardBalance,
  appliedStoredValue,
  finalTip,
}) => {
  const giftCardLeftBalance = giftCardBalance - appliedStoredValue - finalTip;
  if (giftCardLeftBalance < 0) {
    return Math.abs(giftCardBalance - appliedStoredValue);
  }
  return roundFloat(appliedStoredValue + finalTip);
};
