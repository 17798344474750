import * as React from "react";
import Styled from "styled-components";
import { LocationCard as LocationCardComponent } from "@lunchboxinc/lunchbox-components-v2/dist/templateComponents";
import DynamicLocationCard from "@lunchboxinc/dynamic-web/dist/LocationCard";

import config from "utils/config";
import images from "utils/Images";
import { formatPhoneNumber } from "utils/helpers/string";
import { combineValues } from "utils/helpers/time";

import { useStructure } from "components/providers/Structure/StructureContext";
import { useGlobalConfig } from "components/providers/GlobalConfig/globalConfig";
import useSessionStorage from "hooks/useSessionStorage";
import { BaseLocation } from "../../../@types/location";
import withLocationCard from "./withLocationCard";

const LocationCard = withLocationCard(LocationCardComponent);
const LocationCardWrapper = Styled.li`
  margin-bottom: 1rem;
`;

interface LocationListProps {
  diningOption: OrderType;
  locations: BaseLocation[];
  onClick: (location: BaseLocation, params?: string) => void;
  onSeeAllRestaurants: (location: BaseLocation) => void;
  refs: (id: string, node: HTMLElement) => void;
  templateName: string;
  type: string;
}

function LocationList({
  locations = [],
  refs,
  onClick,
  diningOption,
  onSeeAllRestaurants,
  ...props
}: LocationListProps) {
  const globalContext = useGlobalConfig();
  const { locationCardStructure, locationCardConfig, locationCardStyles } =
    useStructure();
  const [_, setLocationSelected] = useSessionStorage("locationSelected", null);
  const handleScheduleLater = (location: BaseLocation) => {
    onClick(location, "isLocationModalOpen=true");
  };

  return (
    <>
      {locations.map((location, idx) => {
        const {
          address,
          id,
          hours,
          distanceInMiles,
          isOpen,
          name,
          isPreorderRequired,
          isAcceptingPreorders,
          phone,
          restaurantGroups,
          serviceEstimates,
        } = location;
        // Decide whether to use location card here
        if (
          config.theme.location.card.use_dynamic_component &&
          typeof config.theme.location.card.use_dynamic_component !==
            "undefined"
        ) {
          const formattedPhone = formatPhoneNumber(location.phone?.value || "");
          const estTime = combineValues(
            location.serviceEstimates,
            diningOption,
          );

          return (
            <LocationCardWrapper key={location.id}>
              <DynamicLocationCard
                location={{
                  address: location.address,
                  distanceInMiles,
                  estimatedTime: estTime ? `${estTime} min` : "",
                  hours: location.hours,
                  isOpen: location.isOpen,
                  name: location.name,
                  phoneNumber: formattedPhone,
                  restaurantGroups,
                }}
                structure={locationCardStructure}
                onOrderClick={() => {
                  setLocationSelected(true);
                  onClick(location);
                }}
                onScheduleClick={() => {
                  setLocationSelected(true);
                  handleScheduleLater(location);
                }}
                onMarketplaceClick={() => onSeeAllRestaurants(location)}
                config={locationCardConfig}
                sx={locationCardStyles}
              />
            </LocationCardWrapper>
          );
        }

        return (
          <React.Fragment key={location.id}>
            <LocationCard
              address={address}
              cellType={props.type}
              diningOption={diningOption}
              distanceInMiles={distanceInMiles}
              hours={hours}
              idx={idx}
              imageSrc={images?.art_location}
              innerRef={(node: HTMLElement) => {
                refs(id, node);
              }}
              isOpen={isOpen}
              isAcceptingPreorders={isAcceptingPreorders}
              isPreorderRequired={isPreorderRequired}
              key={id}
              name={name}
              onEnterMenu={() => {
                localStorage.setItem("items", "[]");
                setLocationSelected(location);
                window.location.replace(
                  `/location/${location.slug}/${diningOption}/${location.restaurantGroup}`,
                );
                onClick(location);
              }}
              onEnterMenuScheduleLater={() => {
                setLocationSelected(location);
                handleScheduleLater(location);
              }}
              onSeeAllRestaurants={() => onSeeAllRestaurants(location)}
              phone={phone}
              restaurantGroups={restaurantGroups}
              serviceEstimates={serviceEstimates}
              themeType={
                config.theme.location.card.type ||
                config.theme.location.card ||
                "Type1"
              }
            />
          </React.Fragment>
        );
      })}
    </>
  );
}

export default LocationList;
