import * as React from "react";
import { formatPrice } from "utils/helpers/string";
import { formatAddress } from "utils/helpers/geo";
import { Copy, config } from "utils";

const titleMap = {
  delivery: (order) => formatAddress(order.deliveryInfo),
  dinein: () => "Dine in",
  kiosk: () => "Table Side",
  pickup: (order) => order.location.name,
};

const withHistoryCard =
  (Component) =>
  ({ order, viewReceipt, reOrder, cellType }) => {
    const orderId = order.id;
    const title = titleMap[order.orderType](order);
    const cardItems = order.items.map((item) => ({
      name: item.name,
      price: formatPrice(item.price),
    }));
    const totalPrice = formatPrice(order.totalPrice);

    const buttonsActions = [
      {
        action: () => viewReceipt(orderId),
        content: Copy.PROFILE_STATIC.VIEW_RECEIPT_BUTTON_TEXT,
        type: "primary",
      },
      {
        action: reOrder,
        content: Copy.PROFILE_STATIC.REORDER_BUTTON_TEXT,
        type: "secondary",
      },
    ];

    return (
      <Component
        themeType={config?.theme?.order_history?.history_card || "Type1"}
        actionButtons={
          config.id === "tacopalenque"
            ? buttonsActions.slice(0, 1)
            : buttonsActions
        }
        title={title}
        price={totalPrice}
        items={cardItems}
        cellType={cellType}
      />
    );
  };

export default withHistoryCard;
